<script setup>
import {onActivated, computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const route = useRoute();
const memberStore = MemberStore();

const {memberData} = storeToRefs(memberStore);
const course = ref(null);

/**
 * Data
 */
const loading = ref(true);

/**
 * Computed
 */
const sortedSessionList = computed(() => {
	if (!course.value || !course.value.courseSessions) {
		return [];
	}

	const sorted = course.value.courseSessions;
	sorted.sort((a, b) => a.id - b.id); // sort by id
	sorted.sort((a, b) => a.ordering - b.ordering); // sort by ordering

	return sorted;
});

/**
 * Mounted
 */
onMounted (() => {
	getCourseData();
});

/**
 * Activated
 */
onActivated (() => {
	if (!course.value) {
		return
	}

	if (course.value.id.toString() !== route.params?.id.toString()) {
		getCourseData();
	}
})


/**
 * Actions
 */
const getCourseData = () => {
	memberStore.getCourse(route.params?.id)
		.then(item => {
			course.value = item;
		})
		.finally(() => {
			loading.value = false;
		});
}
const courseStatus = id => {
	if (!memberData.value || (!memberData.value.course?.expires[id])) {
		return 0;
	}

	if (new Date() > new Date(`${memberData.value.course?.expires[id]} 23:59:59`)) {
		return -1;
	}

	return 1;
}
</script>

<style lang="scss" scoped>
::v-deep {
	img {
		object-fit: cover;
	}
	.p-card-body, .p-card-content {
		padding: 0;
	}

	.right-detail {
		.p-card-title, .p-card-subtitle, .p-card-subtitle + div {
			max-height: 100px;
			overflow-y: hidden;
		}
	}

	.hilight-img {
		position: absolute;
    left: -28px;
    right: -28px;
    top: -28px;
		z-index: -1;
	}
	.back-btn {
		position: absolute;
		top: 0;
	}
}
::v-deep .p-component-overlay {
	animation: unset !important;
	background-color: rgba(255, 255, 255, 0.4);
}
</style>

<template>
	<div
		v-if="course && courseStatus(course.id) === 1"
		class="relative"
		:style="course.picture_url_detail ? 'padding-top: 200px;' : ''"
	>
		<Image
			v-if="course.picture_url_detail"
			:src="course.picture_url_detail"
			class="hilight-img"
			imageClass="w-full"
			imageStyle="max-height: 300px;"
		/>

		<Button
			label="กลับ"
			icon="pi pi-angle-double-left"
			class="back-btn p-button-lg p-button-outlined p-button-raised text-white"
			@click="router.push({name: 'courses'})"
		/>

		<Card
			v-if="course.title_detail || course.title || course.editor_description_detail || course.editor_description"
			class="w-full md:w-10 lg:w-8 xl:w-7 mx-auto p-4 mb-6"
		>
			<template #title>
				<div class="text-center">
					{{course.title_detail || course.title}}
				</div>
			</template>
			<template #content>
				<div v-html="course.editor_description_detail || course.editor_description"></div>
			</template>
		</Card>

		<template v-for="(act, i) in sortedSessionList">
			<div
				v-if="act"
				:key="`session-${i}`"
				class="session-list mb-3"
			>
				<BlockUI
					:blocked="false"
					:baseZIndex="1103"
					:autoZIndex="false"
				>
					<router-link :to="{name: 'course-episode', params: {id: course.id, ep: act.id}}">
						<Card class="mx-auto w-full md:w-11 lg:w-9 xl:w-8">
							<template #content>
								<div
									class="grid grid-nogutter"
									style="max-height: 250px;"
								>
									<div class="col-6 sm:col-5 md:col-4 lg:col-3 xl:col-2">
										<Image
											:src="act.picture_url || 'https://cdn.uhas.com/avatar/no-img.png'"
											imageClass="border-round-left w-full h-full"
											imageStyle="max-height: 250px;"
										/>
									</div>
									<div class="col-6 sm:col-7 md:col-8 lg:col-9 xl:col-10 p-3 md:p-4 right-detail">
										<div class="h-full flex flex-column">
											<div class="p-card-subtitle">{{act.sub_title}}</div>
											<div class="p-card-title">{{act.title}}</div>
											<div v-html="act.description" />
										</div>
									</div>
								</div>
							</template>
						</Card>
					</router-link>
				</BlockUI>
			</div>
		</template>
	</div>
	<ProgressBar
		mode="indeterminate"
		style="height: 0.3em"
		class="mt-4"
		:class="{'hidden': !loading}"
	/>
	<Card v-if="(!loading && !course) || (course && courseStatus(course.id) < 1)" class="mx-auto text-center p-4">
		<template #content>
			<div class="font-bold text-2xl text-gray-600 mb-3">ไม่พบหน้าที่ต้องการ</div>
			<router-link :to="{name: 'courses'}">กลับไปหน้าคอร์สเรียน</router-link>
		</template>
	</Card>
</template>
